<template>
  <v-container>
    <v-row>
      <v-col cols="6">
        <imageCard :height='250' v-model="image1" label="轮胎" url="https://picsum.photos/350/165?random"></imageCard>
      </v-col>

      <v-col cols="6">
        <imageCard :height='250' v-model="image2" label="轮胎2" url=""></imageCard>
      </v-col>
    </v-row>
    <div>image1: {{ image1.name || '' }}</div>
    <div>image2: {{ image2.name || '' }}</div>
    <datepickerField :error="error" label="labellabel" v-model="date"></datepickerField>
    <sendMessageToHook></sendMessageToHook>
    <area-field dense outlined v-model="area"></area-field>
    <area-field dense outlined @change="(v)=>{this.area = v}"></area-field>
    <v-divider></v-divider>
    <div>{{area}}</div>
  </v-container>
</template>

<script>
import imageCard from "@/components/image-card";
import datepickerField from "@/components/datepicker-field";
import areaField from "@/components/area-field";
import sendMessageToHook from "@/components/sendMessageToHook";

export default {
  name: "index",
  components: {
    imageCard, datepickerField,areaField,sendMessageToHook
  },
  data() {
    return {
      image1: {},
      image2: {},
      date: '',
      error: [],
      area:{}
    }
  },
  created() {
    const list = [{
      name: 'A'
    },
      {
        name: 'B'
      }
    ]
    let filter = list.filter(s => {
      return s.name === 'A'
    })
    console.log(list[0] === filter[0])
  }
}
</script>

<style scoped>

</style>