<template>
  <div>
    <v-btn @click="send()"> send</v-btn>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: "sendMessageToHook",
  methods: {
    send() {
      axios.post('https://open.feishu.cn/open-apis/bot/v2/hook/548e1a98-7ab5-4291-afdd-09f7edce5f6e',
          {
            "msg_type": "text",
            "content": {
              "text": "王文博 是sb,你们认同么"
            }
          }).then(s => {
        console.log(s)
      }).catch(error => {
        console.error(error)
      })
    }
  }
}
</script>

<style scoped>

</style>