<template>
  <div>
    <v-row>
      <v-col cols="4">
        <v-autocomplete v-bind="$attrs" label="省" :items="provinceList"
                        return-object
                        v-model="area.province"></v-autocomplete>
      </v-col>
      <v-col cols="4">
        <v-autocomplete v-bind="$attrs" label="城市" :items="cityList"
                        v-model="area.city"
                        return-object
                        :disabled="cityList.length===0"></v-autocomplete>
      </v-col>
      <v-col cols="4">
        <v-autocomplete v-bind="$attrs" label="区域" :items="countyList"
                        v-model="area.county"
                        return-object
                        :disabled="countyList.length === 0"></v-autocomplete>
      </v-col>
    </v-row>
    <div>
      <div>{{ area.province }}</div>
      <div>{{ area.city }}</div>
      <div>{{ area.county }}</div>
    </div>

  </div>
</template>

<script>
import {areaList} from '@vant/area-data';


export default {
  name: "area-field",
  inheritAttrs: false,
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {
      type: Object,
      default() {
        return {
          province: {},
          city: {},
          county: {}
        }
      }
    }
  },
  data() {
    return {
      area: {}
    }
  },
  mounted() {
    this.setField(this.value)
  },
  methods: {
    setField(v) {
      const area = JSON.parse(JSON.stringify(v))
      const {province = {}, city = {}, county = {}} = area
      console.log(province,city,county)
      this.area.provoince = province
      this.area.city = city
      this.area.county = county
    }
  },
  computed: {
    provinceList() {
      return Object.entries(areaList.province_list)
          .map(([value, text]) => {
            return {value, text}
          })
    },
    cityList() {
      if (this.area.province.value) {
        return Object.entries(areaList.city_list)
            .map(([value, text]) => {
              return {value, text}
            })
            .filter(s => {
              return s.value.substring(0, 2) === this.area.province.value.substring(0, 2)
            })
      }
      return []
    },
    countyList() {
      if (this.area.city.value) {
        return Object.entries(areaList.county_list)
            .map(([value, text]) => {
              return {value, text}
            })
            .filter(s => {
              return s.value.substring(0, 2) === this.area.city.value.substring(0, 2)
            })
      }
      return []
    },
  },
  watch: {
    'area.province'() {
      this.area.city = {}
    },
    'area.city'() {
      this.area.county = {}
    },
    'area.county'(v) {
      if (v.value){
        this.$emit('change', this.area)
      }
    },
    'value'(v1,v2) {
      console.log('watch value',v1,v2)
      console.log('watch value',v1===v2)

      if (v1===v2) return
      this.setField(v1)
      console.log(this.area)
    }
  }
}
</script>

<style scoped>

</style>